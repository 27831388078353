import axios, { AxiosResponse, AxiosError } from 'axios';
import { refreshToken } from '../api/Aws/authApi';
import { classifyAndThrowError } from './ApiError';
import { LoginUserParams } from './Aws/apiParamsTypes';

function getAuthToken(useIdToken: boolean): string {
  const tokenKey = useIdToken ? 'idToken' : 'token';
  return localStorage.getItem(tokenKey) || '';
}

const axiosApiInstance = axios.create({ timeout: 50000 });

let isRefreshing = false;
let failedQueue: Array<() => void> = [];

const processQueue = (error: unknown) => {
  failedQueue.forEach(() => {
    classifyAndThrowError(error);
  });
  failedQueue = [];
};

/**
 * Interceptor to add the jwt to the authorization header for each request
 */
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const useIdToken = config.headers?.['X-Use-Id-Token'] === 'true';
    const token = getAuthToken(useIdToken);
    if (useIdToken) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers.Authorization = JSON.stringify({ token: token });
    }

    // Clean up custom header to not leak it into actual requests
    delete config.headers['X-Use-Id-Token'];

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve) => {
          failedQueue.push(() => {
            // Determine whether to use idToken or accessToken for the Authorization header
            const useIdToken = originalRequest.headers?.['X-Use-Id-Token'] === 'true';
            const tokenKey = useIdToken ? 'idToken' : 'token';
            if (useIdToken) {
              originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem(tokenKey)}`;
            } else {
              originalRequest.headers['Authorization'] = `${localStorage.getItem(tokenKey)}`;
            }
            resolve(axiosApiInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const refreshTokenValue = localStorage.getItem('refreshToken');

      if (!refreshTokenValue) {
        processQueue(new Error('Refresh token not found in session'));
        return Promise.reject('Refresh token not found in session');
      }
      const refreshTokenPayload: LoginUserParams = {
        userData: {
          refreshToken: refreshTokenValue
        }
      };

      return refreshToken(refreshTokenPayload)
        .then((response) => {
          if (!response) {
            // TODO: Handle this better, this is a temporary fix.
            throw new Error('Refresh token failed');
          }

          const { accessToken, idToken } = response.data;
          if (accessToken) {
            localStorage.setItem('token', accessToken);
          }
          if (idToken) {
            localStorage.setItem('idToken', idToken);
          }
          isRefreshing = false;
          // Again check whether to use idToken or accessToken for the Authorization header
          const useIdToken = originalRequest.headers?.['X-Use-Id-Token'] === 'true';
          if (useIdToken) {
            originalRequest.headers['Authorization'] = `Bearer ${idToken}`;
          } else {
            originalRequest.headers['Authorization'] = `${accessToken}`;
          }
          processQueue(null);
          return axiosApiInstance(originalRequest);
        })
        .catch((err) => {
          localStorage.removeItem('token');
          localStorage.removeItem('idToken');
          localStorage.removeItem('refreshToken');
          isRefreshing = false;
          processQueue(err);
          return Promise.reject(err);
        });
    }

    if (error instanceof AxiosError) {
      return Promise.reject(error);
    }
  }
);

export default axiosApiInstance;
